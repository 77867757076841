import React from 'react';
import Layout from '../layouts';

export default function Index() {
  return (
    <Layout>
      {' '}
      <div />
    </Layout>
  );
}
